import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-download',
  templateUrl: './bottom-download.component.html',
  styleUrls: ['./bottom-download.component.css']
})
export class BottomDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
