<div class="container">
  <div class="container_header">
    <h1 class="container_header_text">How It <b>Work?</b></h1>
  </div>
  <div class="container_phones">
    <div class="phone1" data-aos="fade-up" data-aos-duration="1500">
        <img class="number1" src="assets/images/1.png" alt="">
        <img class="phone1_img" src="assets/images/phone3.png" alt="">
        <div class="mobile_text">
          <p class="main_text">Copy</p>
          <p class="sec_text">phone number</p>
        </div>
    </div>
    <div class="phone_2 phone1" data-aos="fade-up" data-aos-duration="1500">
      <div class="mobile_text">
        <p class="main_text">Paste|Write</p>
        <p class="sec_text">the phone number</p>
      </div>
      <img class="number2" src="assets/images/2.png" alt="">
      <img class="phone2_img" src="assets/images/phone2.png" alt="">
    </div>
    <div class="phone_3 phone1"  data-aos="fade-up" data-aos-duration="1500"
    >
      <img class="number3" src="assets/images/3.png" alt="">
      <img class="phone3_img" src="assets/images/phone1.png" alt="">
      <div class="mobile_text">
        <p class="main_text" >Start</p>
        <p class="sec_text">WhatsApp conversation</p>
      </div>
    </div>
  </div>
  <div class="container_phone_text" data-aos="flip-right" data-aos-duration="1500">
    <div class="copy">
      <p class="workheader copytext">Copy</p>
      <p class="subtext copytext">phone number</p>
    </div>
    <div class="paste">
      <p class="workheader">Paste | Write</p>
      <p class="subtext">the phone number</p>
    </div>
    <div class="start">
      <p class="workheader">Start</p>
      <p class="subtext">WhatsApp conversation</p>
    </div>
  </div>
  <div class="icons">
    <div class="dots">
      <img src="assets/images/dots_icon.png" alt="">
    </div>
    <div class="icon_img">
      <div class="person">
        <img class="person_img" src="assets/images/person_icon.png" alt="">
        <div class="person_text space">
          <p>Have you ever wished you could have saved for a later time things like website links, news, remindings, etc?</p>
          <p></p>
          <p>so you may send it by WhatsApp to family members, friends, partners, etc?</p>
          <p><b>Now with StartChat you can send it to your WhatsApp phone number and save it</b></p>
          <div class="space2">
            <p class="phonenumber">Write your phone number</p>
            <p><b>and start WhatsApp conversation</b></p>
          </div>
        </div>
      </div>
      <div class="icon_group">
        <img class="group" src="assets/images/group_icon.png" alt="">
        <div class="group_text space">
          <p>StartChat provides you with a simple way to start your WhatsApp conversation with people without adding them to your contact list.</p>
          <div class="space3">
          <p class="phonenumber">Write or Copy phone number</p>
          <p><b>and start WhatsApp conversation</b></p>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
