
<div class="container">
  <div class="container_contant">
      <div class="text">
        <p class="main_text">Download <b>App</b></p>
        <div class="container_btn">
          <a class="container_btn_a" href="https://play.google.com/store/apps/details?id=org.nativescript.StartChat" target="_blank">
          <img class="container_btn_google" src="assets/images/google-play-badge.png" alt="google play">
          </a>
        </div>
      </div>
      <img class="container_img" src="assets/images/mobile_bottom-image.png" alt="google play">
      <div class="bottom_logo">
      <img class="mobile_logo" src="assets/images/mobile_bootom_startchatlogo.png" alt="">
      <img class="logo" src="assets/images/bootom_startchatlogo.png" alt="">
    </div>
  </div>
</div>
