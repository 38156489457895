<div class="container">
  <div class="container_logo">
    <img class="container_logo_Img" src="assets/images/Header_Logo.png" alt="StartChat Logo">
    <div class="container_text">
      <p class="container_main_text">WhatsApp to people
      <span class="container_secound_text">without adding them</span>
      </p>
    </div>
    <div class="container_text_content">
    <p>simple way to start Whatsapp conversation with people  without adding them to your contact list.</p>
    <p>save for later time things like websites linkes, news, remindings and etc. by sending them to your phone number.</p>
    </div>
    <div class="container_btn">
        <a class="container_btn_a" href="https://play.google.com/store/apps/details?id=org.nativescript.StartChat" target="_blank">
        <img class="container_btn_google" src="assets/images/google-play-badge.png" alt="google play">
        </a>
    </div>
  </div>
  <div class="container_header_phone" data-aos="fade-left"
  data-aos-duration="1500"
  data-aos-easing="ease-in-sine">
    <img class="container_header_phone_img" src="assets/images/header_phone.png" alt="">
  </div>
</div>
