<div class="container">
    <div class="pc_container_video">
      <img class="video_border" src="assets/images/computer.png" alt="">
      <iframe class="video" src="https://www.youtube.com/embed/7_gIbNoRza4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
     <div class="mobile_container_video">
      <img class="video_border" src="assets/images/mobile_computer.png" alt="">
      <iframe class="video" src="https://www.youtube.com/embed/7_gIbNoRza4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
</div>
