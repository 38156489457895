<div class="container">
  <div class="container_heder_text">
    <p class="container_heder_text_first">Add the requested phone number</p>
    <p>to start a WhatsApp conversation</p>
  </div>
  <div class="container_search">
      <div class="search_areacode">
        <div class="" #insideElement (click)="openPopup()">
          <i class="flag_icon pointer"><img class="dropdown_icon_img" src="assets/images/dropdown.png" alt=""></i>
          <i class="flag_icon pointer"><img class="flag_icon_img" [src]="countryFlag" alt=""></i>
          <input class="container_areacode searchfields pointer" maxlength="5" [placeholder]="areacode" type="number">
        </div>
      <div class="country"  *ngIf="areaCodePopup">
        <app-search-field-country (onSelectAreaCode)="onChangeAreaCode($event)"></app-search-field-country>
      </div>
      </div>
      <div>
      <input class="container_phonenumber searchfields" [ngClass]="{'phone_error': error}"  placeholder="phone number" [(ngModel)]="binding" type="text">
      <p *ngIf="error" class="error">Please enter valid Number</p>
     </div>
      <button (click)="onSubmited()" class="container_search_btn">Start a WhatsApp conversation</button>
  </div>
</div>
